
import ApiService from "@/services/apiService";
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonLabel, IonInput,IonItem,IonButton } from '@ionic/vue';
import { Storage } from '@ionic/storage';
// import Tabs from '@/views/Tabs.vue'
export default  {
  name: 'Login',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonLabel, IonInput, IonItem, IonButton },
  methods: {
    login() {
         // Simple GET request using axios
    //Remember to give ROCK RMS Key Security Access in Gateway Rock
    const config = {
      headers: {
        "Accept": "application/json"
      },
      
    };
    const data = {
          "Username": "EthanW96",
          "Password": "NatsuMarsh2016",
          "Persisted": true
      }
    ApiService
      .postWithConfig("https://portal.gatewaybaptist.com/api/Auth/Login", data, config)
      .then( (response) => {
    console.log(response.data);
    console.log(response.status);
    console.log(response.statusText);
    console.log(response.headers);
    console.log(response.config);
    this.storeAuth(document.cookie);

    
  })
    },
    async storeAuth(auth: string) {
      const store = new Storage();
      await store.create();
      await store.set('AuthToken', auth);
    }
  }
}
